import React, {Component} from "react"
import ReactDOM from "react-dom"
import styles from "./Popup.module.css"
import {Gate} from "lib/gate/Gate"
import {action, computed, observable} from "mobx"
import {observer} from "mobx-react"
import {ReactComponent as Icon} from "./parts/img/fill.svg"
import {PopupButton} from "../popupButtons/PopupButton"
import {CloseButton} from "../popupButtons/CloseButton"
import {Model} from "../../Model"
import {Input} from "components/inputs/input"

interface Props {
    gate: Gate
    model: Model
    onClose: () => void
    onSubmit
    onChange
    submitButtonText: string
    title: string
    jsonData
    fieldId: string
    fieldValue: string
}

@observer
export class Popup extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    @observable
    private errorMessage: string[] | null = null

    public componentDidMount() {
        document.getElementById("appModal").style.display = "block"
    }

    public componentWillUnmount() {
        document.getElementById("appModal").style.display = "none"
    }

    private async onSubmit() {
        const result = await this.props.onSubmit(this.props.fieldId, this.props.fieldValue)
        this.errorMessage = null
        if (result.success !== undefined) {
            if (!result.success) {
                this.errorMessage = result.message
            }
        }
    }

    public render() {
        const {title, submitButtonText, jsonData, fieldId, fieldValue} = this.props
        const p = {json_data: jsonData, onChange: this.props.onChange, errors: this.errors}

        let html = (
            <article className={styles.popup}>
                <div className={styles.iconWrapper}>
                    <Icon className={styles.icon} width="39" height="39" />
                </div>
                <div className={styles.body}>
                    <div className={styles.closeButtonWrapper}>
                        <CloseButton onClick={this.props.onClose} />
                    </div>

                    <h1 className={styles.title}>{title}</h1>

                    <Input id={fieldId} {...p} value={fieldValue} />
                    {this.errorMessage !== null && (
                        <p className={styles.error}>{this.errorMessage} </p>
                    )}

                    <div className={styles.buttonWrapper}>
                        <PopupButton
                            text={submitButtonText}
                            type="secondary"
                            onClick={this.onSubmit.bind(this)}
                        />
                    </div>
                </div>
            </article>
        )

        return ReactDOM.createPortal(html, document.getElementById("appModal"))
    }
}
