import React, {Component} from "react"
import styles from "./Navigation.module.css"
import classNames from "classnames"
import {FormID} from "interfaces"
import {observer} from "mobx-react"
import {Item} from "./parts/Item"
import {observable} from "mobx"
import {Model} from "Model"

interface Props {
    checkedItems: FormID[]
    enabledItems: FormID[]
    selected: FormID
    onChange: (form_id: FormID) => void
    mode: "mobile" | "tablet" | "desktop"
    className?: string
    model: Model
}

const tabs = [
    {
        id: "1",
        title: "Career Information",
        tabletTitle: "Career Info",
        items: [
            {
                label: "Program Selection",
                value: "application_career_information"
            }
        ],
        disable: false
    },
    {
        id: "6",
        title: "Forms",
        tabletTitle: "Forms",
        items: [{value: "enrollment_application", label: "Enrollment application"}],
        disable: false
    },
    {
        id: "7",
        title: "Upload docs",
        tabletTitle: "Upload docs",
        items: [
            {
                label: "Documents",
                value: "application_upload_docs"
            }
        ],
        disable: false
    },
    {
        id: "10",
        title: "Payment",
        tabletTitle: "Payment",
        items: [
            {
                label: "Payment",
                value: "payment"
            }
        ],
        disable: false
    },
    {
        id: "8",
        title: "Submission",
        tabletTitle: "Submission",
        items: [
            {
                label: "Submission",
                value: "submission"
            }
        ],
        disable: false
    }
]

@observer
export class Navigation extends Component<Props, {}> {
    private INDEX_DISABLE = 1
    private DISABILITES_YES = "yes"

    @observable
    private openedTab: string = null

    public componentDidMount(): void {
        if (this.props.mode === "desktop") {
            this.openedTab = this.getTabIdByFormId(this.props.selected)
        } else {
            this.openedTab = null
        }
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.props.selected === prevProps.selected) {
            return
        }

        if (this.props.mode === "desktop") {
            this.openedTab = this.getTabIdByFormId(this.props.selected)
        } else {
            this.openedTab = null
        }
    }

    private getTabIdByFormId(form_id: FormID): string {
        for (const tab of tabs) {
            const hasForm = tab.items.find((el) => {
                if (el.value === form_id) {
                    return true
                }
                return false
            })
            if (hasForm) {
                return tab.id
            }
        }

        return null
    }

    private disableTab = (tabId) => {
        if (
            this.props.model.document.json_data.disabilites &&
            this.props.model.document.json_data.disabilites === this.DISABILITES_YES &&
            +tabId > this.INDEX_DISABLE
        ) {
            return true
        }
        return false
    }

    public render() {
        const {selected, onChange, className} = this.props
        const {disableTab} = this
        return (
            <div className={classNames(styles.root, className)}>
                <ul className={styles.list}>
                    {tabs.map((el) => {
                        return (
                            <Item
                                key={el.id}
                                id={el.id}
                                title={el.title}
                                tabletTitle={el.tabletTitle}
                                items={el.items as any}
                                onTitleClick={(tab_id) => {
                                    console.log("TAB " + tab_id)
                                    this.openedTab = tab_id
                                }}
                                onClick={(id: FormID) => {
                                    if (this.props.mode !== "desktop") {
                                        // Close submenu for tablet and mobile
                                        this.openedTab = null
                                    }
                                    onChange(id)
                                }}
                                selected={selected}
                                checkedItems={this.props.checkedItems}
                                enabledItems={this.props.enabledItems}
                                isOpen={this.openedTab === el.id}
                                disableTab={disableTab(el.id)}
                            />
                        )
                    })}
                </ul>
            </div>
        )
    }
}
