import React, {Component} from "react"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {Model} from "./Model"
import {Gate} from "./lib/gate/Gate"
import {Header} from "components/Header"
import {Footer} from "components/Footer"
import {Form} from "components/Form"
import {Loader} from "components/Loader"
import styles from "./App.module.css"
import {NavigationButton} from "components/NavigationButton"
import {FormID} from "./interfaces"
import {AdaptiveNavigation} from "./components/AdaptiveNavigation"
import {SignaturePopup} from "./components/SignaturePopup"
import {getParameterByName} from "./lib/functions/getParameterByName"
import {ALL_FORMS_IN_TABS} from "helper/Methods"

interface Props {
    model: Model
    gate: Gate
}

@observer
export class App extends Component<Props, {}> {
    @observable
    private isLoading = false

    @observable
    private navigation: "open" | "closed" = "open"

    @observable
    private mode: "mobile" | "tablet" | "desktop"

    constructor(props) {
        super(props)

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.forceUpdate()
    }

    private onChangeNavigationState() {
        if (this.navigation === "open") {
            this.navigation = "closed"
        } else {
            this.navigation = "open"
        }
    }
    public render() {
        const {model, gate} = this.props

        return (
            <React.Fragment>
                <Loader isVisible={gate.getLoading()} />
                {model.signaturePopup.show && (
                    <SignaturePopup
                        mode={model.signaturePopup.mode}
                        signature_key={model.signaturePopup.signature_key}
                        gate={gate}
                        model={model}
                        onClose={() => {
                            model.signaturePopup = {
                                show: false,
                                signature_key: "",
                                mode: "signature"
                            }
                        }}
                    />
                )}
                <div className={styles.root}>
                    <Header
                        onClickNavigation={() => {
                            this.onChangeNavigationState()
                        }}
                        model={model}
                    />
                    {this.navigation === "open" && (
                        <div className={styles.navigation}>
                            <AdaptiveNavigation
                                enabledItems={model.enabledItems}
                                checkedItems={
                                    (Object.keys(
                                        model.document?.json_data?.forms || {}
                                    ) as FormID[]) || []
                                }
                                selected={model.form.form_id}
                                onChange={(id: FormID) => {
                                    if (window.innerWidth <= 660) {
                                        this.navigation = "closed"
                                    }
                                    if (ALL_FORMS_IN_TABS.includes(id)) {
                                        model.setFormId(id)
                                    }
                                }}
                                model={model}
                            />
                        </div>
                    )}
                    <main className={styles.main}>
                        <Form model={model} gate={gate} />
                    </main>
                    <NavigationButton
                        model={model}
                        className={styles.navigationButton}
                        setTabForms={() => this.forceUpdate()}
                    />
                    <Footer />
                </div>
            </React.Fragment>
        )
    }

    public async componentDidMount() {
        window.addEventListener("resize", () => {
            if (window.innerWidth > 660) {
                this.navigation = "open"
            } else {
                this.navigation = "closed"
            }
        })
        if (window.innerWidth <= 660) {
            this.navigation = "closed"
        }

        this.isLoading = true
        const token = getParameterByName("token")
        const user_id = parseInt(getParameterByName("user_id"))
        await this.props.model.loadAll(token, user_id)
    }
}
