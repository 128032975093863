import {action, computed, observable, runInAction, toJS} from "mobx"
import {Gate} from "./lib/gate/Gate"
import {Action, History, Location} from "history"
import {User, Visitor} from "./lib/gate/interfaces"
import {Form, FormID} from "./interfaces"
import {route} from "./lib/functions/route"
import {DataStore} from "./data/DataStore"
import {EventDispatcher} from "./lib/gate/EventDispatcher"
import Swal from "sweetalert2"
import {removeJsonDataForms, sizeCurrentFoms, FORMS_PDF} from "./helper/Methods"

const forms: Form[] = [
    {
        form_id: "application_career_information",
        title: "APPLICATION / CAREER INFORMATION",
        type: "web",
        enabled: true
    },
    {
        form_id: "enrollment_application",
        title: "Enrollment application",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "application_upload_docs",
        title: "Application Upload docs",
        type: "web",
        enabled: true
    },
    {
        form_id: "payment",
        title: "Payment",
        type: "web",
        enabled: true
    },
    {
        form_id: "submission",
        title: "submission",
        type: "web",
        enabled: true
    }
]

export class Model {
    public dispatcher = new EventDispatcher()
    public dispatcher2 = new EventDispatcher()
    private validateFunctions: Function[] = []
    private DISABILITY_YES = "yes"

    @observable
    public document: UserDocument = {json_data: {}}

    @observable
    public signaturePopup: {show: boolean; signature_key; mode: "signature" | "initials"} = {
        show: false,
        signature_key: "",
        mode: "signature"
    }

    @observable
    private form_id: FormID = "application_career_information"

    @observable
    public iframeLoaded = false

    @observable
    public location: Location

    @observable
    public path: string

    @observable
    public visitor: Visitor

    @observable
    public user: User | undefined

    @observable
    public appSettings: any

    @observable
    public popup = {visible: false, message: ""}

    public childBridge: any

    @observable
    public is_form_submitted = false

    private parentBridge: any

    @observable
    public careerInfo: any

    @observable
    public campusesInfo: any

    @observable
    public paymentStatus: string = "pending" // pending | failed | completed

    @observable
    public paymentResponse: any = {}

    @observable
    public couponStatus: string = "not-verified" // verified | not-verified

    constructor(public gate: Gate, private history: History, public dataStore: DataStore) {
        this.history = history
        history.listen(this.onHistoryChange.bind(this))
        this.onHistoryChange(history.location, "PUSH") // execute one time
        // @ts-ignore
        this.parentBridge = window.parent.bridge
    }

    public enableForm(form_id: FormID) {
        for (let form of forms) {
            if (form.form_id === form_id) {
                form.enabled = true
                break
            }
        }

        this.dispatcher.dispatch()
    }

    public disableForm(form_id: FormID) {
        for (let form of forms) {
            if (form.form_id === form_id) {
                form.enabled = false
                break
            }
        }
        this.dispatcher.dispatch()
    }

    @action
    public setFormId(id: FormID) {
        console.log("SET FORM ID=", id)
        // this.form_id = id
        this.goTo(`/form/${id}?token=${this.gate.getToken()}&user_id=${this.user?.id}`)
        this.iframeLoaded = false
    }

    @computed
    public get form(): Form {
        return forms.find((el) => {
            return el.form_id === this.form_id
        })
    }

    public goTo(url: string) {
        console.log(`GO TO URL:${url}`)
        this.history.push(url)
    }

    public getForms(): Form[] {
        return forms
    }

    public get enabledItems(): FormID[] {
        let arr = forms.filter((el) => {
            return el.enabled
        })

        return arr.map((el: Form) => {
            return el.form_id
        })
    }

    public getPreviousFormId(): FormID {
        let index: number
        for (let i = 0; i < forms.length; i++) {
            if (forms[i].form_id === this.form_id) {
                index = i
                break
            }
        }
        if (index === 0) {
            return null
        }

        for (let i = index - 1; i >= 0; i--) {
            if (forms[i].enabled) {
                return forms[i].form_id
            }
        }

        return null
    }

    public getNextFormId(): FormID {
        let index: number
        for (let i = 0; i < forms.length; i++) {
            if (forms[i] && forms[i].form_id === this.form_id) {
                index = i
                break
            }
        }

        if (index + 1 === forms.length) {
            return null
        }

        for (let i = index + 1; i < forms.length; i++) {
            if (forms[i].enabled) {
                return forms[i].form_id
            }
        }

        return null
    }

    private onHistoryChange(location: Location, action: Action) {
        this.path = location.pathname
        this.location = location
        console.log("ON HISTORY Change=", this.path)
        if (route("/form/:id(/)", this.path)) {
            // regex
            const {id} = route("/form/:id(/)*", this.path)
            this.form_id = id
        }
    }

    @computed
    public get planStaffUserId(): number {
        if (this.appSettings && this.appSettings.plan_staff_user_id) {
            return parseInt(this.appSettings.plan_staff_user_id)
        }

        return 0
    }

    @computed
    public get agreementStaffUserId(): number {
        // return 60347
        if (this.appSettings && this.appSettings.agreement_staff_user_id) {
            return parseInt(this.appSettings.agreement_staff_user_id)
        }

        return 0
    }

    @action
    public alert(message: string) {
        this.popup.visible = true
        this.popup.message = message
    }

    public get isStaff(): boolean {
        if (!this.visitor) {
            return false
        }
        const role = this.visitor.role
        if (role === "staff" || role === "admin") {
            return true
        }

        return false
    }

    public async loadAppSettings() {
        let r = await this.gate.request("/app-settings/get")
        this.appSettings = r.data.json_data
    }

    public async loadMe(): Promise<void> {
        let r = await this.gate.request("/users/get-me")
        if (!r.success) {
            this.alert("API Error!" + JSON.stringify(r.errors))
        }
        this.visitor = r.data
    }

    public async loadUser(user_id: number): Promise<void> {
        let r = await this.gate.request(`/users/${user_id}/get`)
        this.user = r.data
    }

    public async loadCareerInformation(): Promise<void> {
        const {data} = await this.gate.request(`/careers`)
        this.careerInfo = data
    }

    public async loadCampuses(): Promise<void> {
        const {data} = await this.gate.request(`/campuses`)
        this.campusesInfo = data
    }

    public getHistory(): History {
        return this.history
    }

    public async submit(): Promise<void> {
        let json_data
        json_data = this.document.json_data
        const id = this.user.doc.id
        try {
            if (FORMS_PDF.length === sizeCurrentFoms(json_data.forms || [])) {
                json_data.isSubmitted = true
            }
            const responseSave = await this.gate.request(`/docs/${id}/edit`, {
                data: {json_data}
            })

            if (responseSave.success) {
                console.log(`final submit`)
                const responseSubmit = await this.gate.request(`/users/${this.user.id}/submit`)
                if (responseSubmit.success) {
                    await Swal.fire({
                        icon: "success",
                        title: "Application submitted",
                        text:
                            "Thank you for submit your application. An advisor will contact you soon."
                    })
                } else {
                    delete json_data.isSubmitted
                }
            }
        } catch (error) {}
        runInAction(() => {
            this.is_form_submitted = true
        })
    }

    public async saveAgreement(): Promise<boolean> {
        let json_data
        const id = this.user.doc.id
        if (this.form.type === "web") {
            // if (!this.validate()) {
            //     return false
            // }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }
        json_data.accepted = true
        let r: any = await this.gate.request(`/docs/${id}/edit`, {
            data: {json_data}
        })
        if (r.success) {
            const {
                data: {
                    json_data: {accepted}
                }
            } = r

            json_data.accepted = accepted
        } else {
            if (json_data.accepted) {
                delete json_data.accepted
            }
        }

        return r.success
    }

    public async save(): Promise<boolean> {
        let json_data
        if (this.form.type === "pdf") {
            if (!this.childBridge) {
                throw new Error(
                    "No childBridge! Please check bridge set in admissions-felbrycollege-pdf"
                )
            }
            if (!(await this.childBridge.validate())) {
                return false
            }
            json_data = this.childBridge.json_data
            this.document.json_data = json_data
            this.dispatcher.dispatch()
        }
        console.log("childBridge", this.childBridge)

        if (this.form.type === "web") {
            if (!this.validate()) {
                return false
            }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }

        json_data.forms = json_data.forms || {}
        json_data.forms[this.form.form_id] = this.form

        const APPLICATION_CAREER_INFORMATION: FormID = "application_career_information"
        console.log("my current JSON DATA:", toJS(json_data))

        if (APPLICATION_CAREER_INFORMATION === this.form_id) {
            try {
                const {
                    tuition_data,
                    schedule_data,
                    spf_data,
                    opt_out_data,
                    condition_text_data
                } = await this.generateTuituonByCampusAndProgram(json_data)
                json_data.extra.tuitionInfo = tuition_data
                json_data.extra.optOutInfo = opt_out_data
                json_data.extra.scheduleInfo = schedule_data
                json_data.extra.spfInfo = spf_data
                json_data.extra.conditionTextInfo = condition_text_data
                const currentForms = json_data.forms
                json_data.forms = removeJsonDataForms(currentForms || [])
            } catch (error) {
                console.log(`Something happens while get tuitions: ${error}`)
            }
            console.log("another JSON data:", toJS(json_data.extra.spfInfo))
        }
        console.log("Saving form:", this.form_id)

        const id = this.user.doc.id

        if (json_data.disabilites && json_data.disabilites === this.DISABILITY_YES) {
            json_data.disabilitySent = true
        }
        json_data.disabilitySelected = true

        let r: any = await this.gate.request(`/docs/${id}/edit`, {
            data: {json_data}
        })
        if (r.success) {
            const {
                success,
                data: {
                    json_data: {disabilitySent}
                }
            } = r
            if (success && disabilitySent) {
                await this.submit()
                // Swal.fire({
                //     icon: "success",
                //     title: "Request sent",
                //     text: "Thank you for submit your application. An advisor will contact you soon."
                // })
            }
        } else {
            if (json_data.disabilites && json_data.disabilitySelected) {
                delete json_data.disabilitySent
                delete json_data.disabilitySelected
            }
        }
        return r.success
    }
    private summarizeCosts(dataMatrix: Array<any>, keyField: string): number {
        const totalObject = dataMatrix.reduce(
            (acumulator, currentValue) => ({
                [keyField]: acumulator[keyField] + currentValue[keyField]
            }),
            {[keyField]: 0}
        )
        const {[keyField]: total} = totalObject
        return total
    }
    private async updateTuition(jsonData) {
        const {
            extra: {
                tuitionInfo: {id, tuition}
            }
        } = jsonData
        const tuitionResponse = await this.gate.request(`/tuitions/${id}/edit`, {data: tuition})
        const {
            data: {tuition_data}
        } = tuitionResponse
        return tuition_data
    }

    private async updateSPFTable(jsonData) {
        const {
            extra: {
                spfInfo: {id, spf: spfJsonData}
            }
        } = jsonData
        const spfResponse = await this.gate.request(`/spfs/${id}/edit`, {data: spfJsonData})
        const {data} = spfResponse
        return data
    }

    private async generateTuituonByCampusAndProgram(jsonData) {
        const {
            extra: {
                campus: {id: campusId},
                program: {id: programId}
            }
        } = jsonData
        const careerResponse = await this.gate.request(
            `/careers/campuses/${campusId}/programs/${programId}/get`
        )
        const {
            data: {tuition_data, schedule_data, spf_data, opt_out_data, condition_text_data}
        } = careerResponse
        console.log("response by campus & program:", careerResponse)

        return {tuition_data, schedule_data, spf_data, opt_out_data, condition_text_data}
    }

    public async uploadDocument(file: File, id: string) {
        const IS_MULTI = false
        const formData = new FormData()
        formData.append(id, file)
        const data = await this.gate.request(
            `/uploads`,
            {
                data: formData
            },
            IS_MULTI
        )
        return data
    }

    public signOut() {
        this.parentBridge.signOut()
    }

    public async getSignature(type: "signature" | "initials"): Promise<object | null> {
        if (!this.currentSignature) {
            let r = await this.gate.request("/signatures/get-my", {data: {type: type}})
            if (r.success) {
                this.currentSignature = r.data
            } else {
                this.currentSignature = null
            }
        }

        return this.currentSignature
    }

    public addValidateFunction(f: Function) {
        for (let item of this.validateFunctions) {
            if (item === f) {
                return
            }
        }

        this.validateFunctions.push(f)
    }

    public removeValidateFunction(f: Function) {
        console.log("removeValidateFunction")
        // @ts-ignore
        console.log(f.functionId)

        console.log(
            this.validateFunctions.map((e) => {
                // @ts-ignore
                return e.functionId
            })
        )
        for (let i = 0; i < this.validateFunctions.length; i++) {
            // @ts-ignore
            if (this.validateFunctions[i].functionId === f.functionId) {
                this.validateFunctions.splice(i, 1)
                console.log(
                    this.validateFunctions.map((e) => {
                        // @ts-ignore
                        return e.functionId
                    })
                )
                return
            }
        }
    }

    public validate(): boolean {
        console.log("onValidate:", this.validateFunctions)
        let results = []
        for (let f of this.validateFunctions) {
            const r = f()
            results.push(r)
        }

        if (results.includes(false)) {
            return false
        }
        return true
    }

    public async loadVisitor() {
        let r = await this.gate.request("/users/get-me")
        this.visitor = r.data
    }

    public async loadAll(token: string, user_id: number) {
        if (!token || !user_id) {
            return
        }
        this.gate.setToken(token)
        await Promise.all([
            this.loadUser(user_id),
            this.loadVisitor(),
            this.loadCareerInformation(),
            this.loadCampuses()
        ]) // add a control to view if we have campus selected & program selected to refill tuition

        this.document = this.user.doc
        console.log("MODEL>LOAD ALL")
        console.log(toJS(this.document))
        this.form_id =
            (this.document.json_data.progress as FormID) || "application_career_information"

        if (!this.document.json_data.role) {
            this.document.json_data.role = this.user.role
        }
        if (!this.document.json_data.first_name) {
            this.document.json_data.first_name = this.user.first_name
        }
        if (!this.document.json_data.last_name) {
            this.document.json_data.last_name = this.user.last_name
        }

        if (!this.document.json_data.home_phone) {
            this.document.json_data.home_phone = this.user.json_data.phone_number
        }

        if (!this.document.json_data.full_name) {
            this.document.json_data.full_name = `${this.user.first_name} ${this.user.last_name}`
        }
        if (!this.document.json_data.email) {
            this.document.json_data.email = `${this.user.email}`
        }
        if (!this.document.json_data.about_us) {
            this.document.json_data.about_us = `${this.user.json_data.about_us}`
        }
        if (!this.document.json_data.about_us_text) {
            this.document.json_data.about_us_text = `${this.user.json_data.about_us_text}`
        }
        if (!this.document.json_data.payment_status) {
            this.document.json_data.payment_status = this.paymentStatus
        }
        if (!this.document.json_data.payment_response) {
            this.document.json_data.payment_response = this.paymentResponse
        }
        if (!this.document.json_data.coupon_status) {
            this.document.json_data.coupon_status = this.couponStatus
        }
        if (!this.document.json_data.previously_applied) {
            this.document.json_data.previously_applied = `${this.user.json_data.previously_applied}`
        }

        console.log("autopopulate", toJS(this.document))

        this.dispatcher.dispatch()
        this.dispatcher2.dispatch()
    }

    public async deleteSupportedDocument(id: number) {
        const r = await this.gate.request(`/uploads/${id}/delete`)
        return r
    }

    private currentSignature
}

export interface SignatureVO {
    id
    user_id
    json_data: {
        img: string
    }
    ip // "172.17.0.1"
    created_at // "2019-10-17T11:22:43.823Z"
    updated_at // "2019-10-17T11:22:43.823Z"
    deleted_at
}

interface UserDocument {
    id?: number
    // pack_id
    // visitor_id
    // user_id
    // user: User
    // type
    json_data: JsonDocument
    // created_at
    // updated_at
    // deleted_at
}

export interface JsonDocument {
    [key: string]: string | any
}

export interface CareerDocument {
    [key: string]: any
}
