import moment from "moment"
import {FormID} from "../interfaces"

const formatDateMonthDayYear = (date) => moment(date).format(" MMMM D [,] YYYY")

const formatStandarUSDate = (date) => moment(date).format(" MM/DD/YYYY")

const FORMS_PDF: FormID[] = ["enrollment_application"]

const REFILL_FORMS: FormID[] = ["enrollment_agreement", "opt_outs"]

const removeJsonDataForms = (currentJsonDataForms) => {
    return Object.keys(currentJsonDataForms)
        .filter((key) => !REFILL_FORMS.includes(key as FormID))
        .reduce((obj, key) => {
            obj[key] = currentJsonDataForms[key]
            return obj
        }, {})
}

const isEnableSubmitButton = (currentJsonDataForms) => {
    const filledForms = FORMS_PDF.length
    const jsonFormsKeys = Object.keys(currentJsonDataForms)
    let counter = 0
    for (let i = 0; i < jsonFormsKeys.length; i++) {
        const elementKey = jsonFormsKeys[i]
        if (FORMS_PDF.includes(elementKey as FormID)) counter += 1
    }
    return counter === filledForms ? false : true
}

const sizeCurrentFoms = (currentJsonDataForms) => {
    const jsonFormsKeys = Object.keys(currentJsonDataForms)
    let counter = 0
    for (let i = 0; i < jsonFormsKeys.length; i++) {
        const elementKey = jsonFormsKeys[i]
        if (FORMS_PDF.includes(elementKey as FormID)) counter += 1
    }
    return counter
}

const ALL_FORMS_IN_TABS = [
    "application_career_information",
    "enrollment_application",
    "application_upload_docs",
    "payment",
    "submission"
]

export {
    formatDateMonthDayYear,
    formatStandarUSDate,
    removeJsonDataForms,
    isEnableSubmitButton,
    sizeCurrentFoms,
    FORMS_PDF,
    ALL_FORMS_IN_TABS
}
