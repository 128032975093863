import React, {Component} from "react"
import styles from "./PaymentForm.module.css"
import {Flex, Box, Text, Heading} from "rebass"
import styled from "styled-components"
import logo from "./img/logo.svg"
// import { FormComponent, FormContainer } from "react-authorize-net"
// import R from "ramda"
import {FormID} from "interfaces"
import {Gate} from "lib/gate/Gate"
import {JsonDocument, Model} from "Model"
import FormComponent from "./parts/FormComponent"
import FormContainer from "./parts/FormContainer"
import {Popup} from "../../components/Popup"

const clientKey = process.env.REACT_APP_AUTHORIZENET_CLIENTKEY as string
const apiLoginId = process.env.REACT_APP_AUTHORIZENET_LOGINID as string

const AuthorizeNetAuthInfo = {
    clientKey,
    apiLoginId
}

interface Props {
    model: Model
    gate: Gate
}

type State = {
    status: "paid" | "unpaid" | ["failure", string[]] | "processing" | "loading"
    showPopup: boolean
    couponCode: string
    amount: number
    currentPayment: boolean
    paidAmount: number
}

const Button = styled.button({
    "&:hover": {cursor: "pointer"},
    padding: "10px",
    backgroundColor: "white",
    border: "2px solid black",
    fontWeight: 600,
    borderRadius: "2px"
})

const ErrorComponent = (props: {errors: string[]; onBackButtonClick: () => void}) => (
    <div>
        <Text fontSize={3} fontWeight={"500"} mb={3}>
            Failed to process payment
        </Text>
        {props.errors.map((error) => (
            <Text py={2}>{error}</Text>
        ))}
        <Button onClick={props.onBackButtonClick}>Go Back</Button>
    </div>
)

const Header = (props) => (
    <div className={styles.headerPayment}>
        <h2 className={styles.h2}>Payment</h2>
    </div>
)

export class PaymentForm extends Component<Props, State> {
    state: State = {
        status: "loading",
        showPopup: false,
        couponCode: "",
        amount: 200,
        currentPayment: false,
        paidAmount: 0
    }

    constructor(props) {
        super(props)

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.checkPaymentStatus()
    }

    checkPaymentStatus() {
        const paymentStatus =
            this.props.model.document.json_data.payment_status !== undefined
                ? this.props.model.document.json_data.payment_status
                : ""
        const paymentResponse: any =
            this.props.model.document.json_data.payment_response !== undefined
                ? this.props.model.document.json_data.payment_response
                : null
        console.log(`paymentStatus - ${paymentStatus}`)
        console.log(`paymentResponse - ${paymentResponse}`)

        if (paymentStatus === "completed" && this.props.model.form.form_id == "payment") {
            let isPaid: boolean = true

            if (paymentResponse !== undefined && paymentResponse !== null) {
                if (paymentResponse.transactionResponse !== undefined) {
                    if (paymentResponse.transactionResponse.errors !== undefined) {
                        if (paymentResponse.transactionResponse.errors.error !== undefined) {
                            if (paymentResponse.transactionResponse.errors.error[0] !== undefined) {
                                isPaid = false
                            }
                        }
                    }
                }
            }

            if (isPaid) {
                // this.props.model.setFormId("submission")
                if (this.props.model.document.json_data.payment_amount != undefined) {
                    this.setState({paidAmount: this.props.model.document.json_data.payment_amount})
                } else {
                    this.setState({paidAmount: this.state.amount})
                }
                this.setState({status: "paid"})
            } else {
                this.checkCitizenship()
                this.setState({status: "unpaid"})
            }
        } else {
            this.checkCitizenship()
            this.setState({status: "unpaid"})
        }
    }

    checkCitizenship() {
        const citizenshipCountry =
            this.props.model.document.json_data.citizenship !== undefined
                ? this.props.model.document.json_data.citizenship
                : ""
        console.log(`citizenshipCountry - ${citizenshipCountry}`)

        if (citizenshipCountry === "Non-Resident Alien") {
            this.setState({amount: 200})
        } else if (
            citizenshipCountry !== "Non-Resident Alien" &&
            citizenshipCountry.trim() !== ""
        ) {
            this.setState({amount: 125})
        } else {
            // alert("Please fill enrollment application to make payment")
            this.props.model.setFormId("enrollment_application")
        }
    }

    async componentDidMount() {
        this.checkPaymentStatus()
    }

    onErrorHandler = async (response: any) => {
        const paymentStatus = "failed"

        this.props.model.paymentStatus = paymentStatus
        this.props.model.paymentResponse = {}
        this.props.model.document.json_data.payment_status = paymentStatus
        this.props.model.document.json_data.payment_amount = this.state.amount
        this.props.model.document.json_data.payment_response = {}

        const success = await this.props.model.save()

        this.setState({
            status: ["failure", response.messages.message.map((err) => err.text)]
        })
    }

    onSuccessHandler = async (response: any) => {
        // check and process authorize.net transaction request
        let paymentStatus: string = "failed"
        let paymentResponse: any = {}

        if (response.messages.resultCode.toLowerCase() == "ok") {
            this.setState({status: "processing"})

            // create authorize.net transaction through server api request
            const submitData = {
                paymentResponse: response,
                amount: this.state.amount,
                user: this.props.model.user
            }

            const resultPay = await this.props.gate.request("/authorize-net/pay", {
                data: submitData
            })
            if (resultPay.success === true) {
                paymentStatus = "completed"
                paymentResponse = resultPay.data

                this.setState({status: "paid"})
                this.setState({currentPayment: true})
                this.setState({paidAmount: this.state.amount})
            } else {
                paymentStatus = "failed"
                paymentResponse = resultPay.data
                this.setState({
                    status: ["failure", [resultPay.errors[0].message]]
                })
            }
        } else {
            this.setState({
                status: ["failure", response.messages.message.map((err) => err.text)]
            })
        }

        this.props.model.paymentStatus = paymentStatus
        this.props.model.paymentResponse = paymentResponse
        this.props.model.document.json_data.payment_status = paymentStatus
        this.props.model.document.json_data.payment_amount = this.state.amount
        this.props.model.document.json_data.payment_response = paymentResponse

        const success = await this.props.model.save()
    }

    onSkip = async () => {
        // this.setState({ showPopup: true })
        // todo: skyp pay later popup
        const couponStatus = "verified"
        this.props.model.couponStatus = couponStatus
        this.props.model.document.json_data.coupon_status = couponStatus
        const success = await this.props.model.save()
        // this.setState({ showPopup: false })
        this.props.model.setFormId("submission")
    }

    onSkipSubmit = async (id, value) => {
        if (value.toLowerCase().trim() != process.env.REACT_APP_COUPON_CODE.toLowerCase()) {
            return {
                success: false,
                message: "Invalid Coupon Code Entered"
            }
        }

        const couponStatus = "verified"

        this.props.model.couponStatus = couponStatus
        this.props.model.document.json_data.coupon_status = couponStatus

        const success = await this.props.model.save()
        this.setState({showPopup: false})

        this.props.model.setFormId("submission")
    }

    onSkipChange = async (id: string, value) => {
        this.setState({couponCode: value})
    }

    render() {
        return (
            <div className={styles.root}>
                {this.state.showPopup && (
                    <Popup
                        gate={this.props.gate}
                        model={this.props.model}
                        onClose={() => {
                            this.setState({showPopup: false})
                        }}
                        onSubmit={this.onSkipSubmit}
                        onChange={this.onSkipChange}
                        submitButtonText="Submit"
                        title="Authorization code"
                        fieldId="coupon_code"
                        fieldValue={this.state.couponCode}
                        jsonData={this.props.model.document.json_data}
                    />
                )}
                <Header onSkip={this.onSkip} />
                <Box className="App" p={3}>
                    {this.state.status === "paid" ? (
                        this.state.currentPayment === true ? (
                            <Text fontWeight={"500"} fontSize={3} mb={4}>
                                Thank you for your payment!
                            </Text>
                        ) : (
                            <Text fontWeight={"500"} fontSize={3} mb={4}>
                                You have already made the payment for ${this.state.paidAmount}
                            </Text>
                        )
                    ) : this.state.status === "loading" ? (
                        <Text fontWeight={"500"} fontSize={3} mb={4}>
                            Loading...
                        </Text>
                    ) : this.state.status === "processing" ? (
                        <Text fontWeight={"500"} fontSize={3} mb={4}>
                            Processing your payment...
                        </Text>
                    ) : this.state.status === "unpaid" ? (
                        <FormContainer
                            environment="production"
                            onError={this.onErrorHandler}
                            onSuccess={this.onSuccessHandler}
                            onSkip={this.onSkip}
                            amount={this.state.amount}
                            component={FormComponent}
                            clientKey={clientKey}
                            apiLoginId={apiLoginId}
                        />
                    ) : this.state.status[0] === "failure" ? (
                        <ErrorComponent
                            onBackButtonClick={() => this.setState({status: "unpaid"})}
                            errors={this.state.status[1]}
                        />
                    ) : null}
                </Box>
            </div>
        )
    }
}
